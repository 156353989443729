@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}
body {
    position: static!important;
    min-height: 0!important;
}

@media (max-width: 1023px) {
    body {
        -webkit-user-select: none;  /* Safari */
        -ms-user-select: none;      /* IE 10 and IE 11 */
        user-select: none;          /* Standard syntax */
    }
}

.skiptranslate {
    display: none !important;
}

.goog-logo-link {
    display:none !important;
 }
 
 .goog-te-gadget {
    color: transparent !important;
 }
 
 .goog-te-gadget .goog-te-combo {
    color: blue !important;
 }

.goog-te-banner-frame {
    display: none!important;
}

.goog-text-highlight {
    background: none !important;
    box-shadow: none !important;
  }
  
  
  /* Cacher les éléments de l'UI de Google Translate qu'on ne veut pas voir */
  .goog-te-spinner-pos {
    z-index: 9999;
    position: fixed !important;
  }

  [class^="VIpgJd"],
  div[class^="VIpgJd"],
  span[class^="VIpgJd"],
  *[class^="VIpgJd"] {
    background-color: transparent !important;
    box-shadow: none !important;
    background: none !important;
    stroke: none !important;
    z-index: 0 !important;
  }

.spinner-container {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

apple-pay-button {
    --apple-pay-button-width: 300px;
    --apple-pay-button-height: 50px;
    --apple-pay-button-border-radius: 7px;
    --apple-pay-button-margin: 0px 0px 30px 0px;
    --apple-pay-button-box-sizing: border-box;
}

.apple-pay-button {
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: plain;
    -apple-pay-button-style: black;
    cursor: pointer;
    width: 100%;
  }

.div_input {
  display: inline-block;
  height: 40px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 0.98rem;
  background-color: rgba(var(--main-bg), 1);
  border: 2px solid rgba(var(--default-border-color), 1);
  border-radius: var(--default-border-radius);
  margin: 0;
  padding: 0 0.8rem;
}

.spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-top-color: lightblue;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}